const { notify } = require("@kyvg/vue3-notification");

const inputType = {
    NUMBER: 0,
    STRING: 1,
    DATE: 2,
    ARRAY: 3
}

const dateCondition = {
    ANY: 0,
    GREATER_THAN_NOW: 1,
    LESS_THAN_NOW: 2,
    GREATER_THAN_FIELD: 3,
    LESS_THAN_FIELD: 4
}


const validate = (data, schema) => {
    console.log(data);
    let validationResult = { success: true }

    for(let i = 0; i < schema.length; i++) {
        let element = schema[i];
        switch (element.type) {
            case inputType.NUMBER: {
                const result = validateNumberField(data, element)
                if (result.error) 
                    validationResult = result;
                break;
            } 
            case inputType.STRING: {
                const result = validateStringField(data, element)
                if (result.error) 
                    validationResult = result;
                break;
            } 
            case inputType.DATE: {
                const result = validateDateField(data, element)
                if (result.error) 
                    validationResult = result;
                break;
            } 
            case inputType.ARRAY: {
                const result = validateArrayField(data, element)
                if (result.error) 
                    validationResult = result;
                break;
            } 
        }
        if (validationResult.error)
            break;
    }

    if (validationResult.error) {
        notify({
            text: validationResult.error,
            type: 'error',
            title: 'Validation Failed'
        });
    }

    return validationResult;
}

const validateNumberField = (data, rules) => {
    const value = ""+data[rules.name];
    if (value.length == 0 && !rules.emptyAllowed)
        return {
            success: false,
            error: `${getErrorName(rules)} cannot be empty.`
        }

    const valueParsed = Number.parseFloat(value); 

    if (Number.isNaN(valueParsed))
        return {
            success: false,
            error: `${getErrorName(rules)} can only be a number.`
        }

    if (valueParsed === 0 && !rules.zeroAllowed)
        return {
            success: false,
            error: `${getErrorName(rules)} cannot be zero.`
        }

    return { success: true }
}

const getErrorName = (rules) => {
    if (rules.displayName)
        return rules.displayName;
    return rules.name;
}

const getReferenceFieldErrorName = (fieldName) => {
    if (fieldName)
        return fieldName;
    return 'now';
}

const validateStringField = (data, rules) => {

    if(!data[rules.name])
        return {
            success: false,
            error: `${getErrorName(rules)} have to be filled.`
        }

    const value = ""+data[rules.name];

    if (!rules.emptyAllowed && value.length === 0)
        return {
            success: false,
            error: `${getErrorName(rules)} have to be filled.`
        }

    return { success: true }
}

const validateDateField = (data, rules) => {

    let referenceValue = undefined;
    if (rules.condition !== dateCondition.ANY) {
        referenceValue = Date.now();
        if (rules.condition === dateCondition.GREATER_THAN_FIELD||rules.condition === dateCondition.LESS_THAN_FIELD)
            referenceValue = data[rules.referenceField];
    }

    if (referenceValue) {
        if ((rules.condition === dateCondition.GREATER_THAN_FIELD||rules.condition === dateCondition.GREATER_THAN_NOW)&&data[rules.name] < referenceValue) {
            return {
                success: false,
                error: `${getErrorName(rules)} have to be after ${getReferenceFieldErrorName(rules.referenceField)}.`
            }
        }   
        if ((rules.condition === dateCondition.LESS_THAN_FIELD||rules.condition === dateCondition.LESS_THAN_NOW)&&data[rules.name] > referenceValue) {
            return {
                success: false,
                error: `${getErrorName(rules)} have to be before ${getReferenceFieldErrorName(rules.referenceField)}.`
            }
        } 
    }

    return { success: true }
}

const validateArrayField = (data, rules) => {
    if(!data[rules.name])
        return {
            success: false,
            error: `${getErrorName(rules)} have to be filled.`
        }

    if (!rules.emptyAllowed && data[rules.name].length === 0)
        return {
            success: false,
            error: `${getErrorName(rules)} cannot be empty.`
        }
    
    return { success: true }
}

module.exports = {
    validate,
    inputType,
    dateCondition
}