export default () => {
    return {
        title: 'Pack Title',
        localizables: {
            title: {}
        },
        product_id: '',
        activation_time: Date.now(),
        active_until: Date.now() + 48 * 60 * 60 * 1000,
        price: 0,
        prices: [],
        discount_rate: 0,
        conditions: [],
        reward_claim_type: 0,
        rewards: [],
        type: 0,
        rewards_b: [],
        value_multiplier: 0,
        expires_in: 0,
        layout_index: 0,
        popup_type: 0,
        priority: 0,
        dynamic: 0,
        B: {
            localizables: {
                title: {}
            },
            title: 'Pack Title B',
            product_id: '',
            price: 0,
            prices: [],
            discount_rate: 0,
            value_multiplier: 0,
            reward_claim_type: 0,
            layout_index: 0,
            popup_type: 0,
        }
    }
}