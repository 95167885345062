<template>
  <div class="popup-wrapper" :style="!popupOpen ? { display: 'none'} : {}">
    <div class="popup-container">
      <div class="popup-header">
        <div class="popup-title">
          Duplicate Promotion
        </div>
        <a class="popup-button button-close" @click="$emit('popup-close')">
            <i class="fas fa-times"></i>
        </a>
    </div>
    <div class="popup-content">
        <div class="form-container-inline form-container">
            <p>{{ "Activation Date" }}</p>
            <DatePicker
              v-model="state.activation_time"
              mode="dateTime"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        
        <div class="form-container-inline form-container">
            <p>{{ "Active Until" }}</p>
            <DatePicker
              v-model="state.active_until"
              mode="dateTime"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="form-container-inline form-container">
            <p>{{ "Expires In(Hours)" }}</p>
            <input
              v-model="state.expires_in"
              type="number" max="100" min="0">
        </div>
        <div class="form-container-inline form-container">
          <h3>{{ $t(`PROMOTION_VALUES.CONDITIONS`) }}</h3>
          <div v-for="(reward, index) in state.conditions" v-bind:key="index" class="form-container-inline form-container">
              <select v-model="state.conditions[index].type">
                <option v-for="option in promotionConstants.CONDITION_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('PROMOTION_VALUES.' + option.text) }}</option>
              </select>
              <select v-model="state.conditions[index].operator">
                <option :disabled="!option.available_on.includes(state.conditions[index].type)" v-for="option in promotionConstants.CONDITION_OPERATORS[0]" :value="option.value" v-bind:key="option.value">{{ $t('PROMOTION_VALUES.' + option.text) }}</option>
              </select>
              <input v-model="state.conditions[index].value"/>
              <button @click="state.conditions.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
        <button @click="addCondition" class="success">{{ $t('PROMOTION_VALUES.ADD_CONDITION') }}</button>
      </div>
      <div class="popup-footer">
        <button class="success" @click="emitSave">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { notify } from "@kyvg/vue3-notification";
import promotionConstants from "../constants/promotionConstants";

export default {
  name: "DuplicatePromoPopup",
  props: ["content", "popupOpen"],
  emits: ["popup-close"],
  setup(props, { emit }) {
    const state = ref(JSON.parse(JSON.stringify({...props.content})));
    watch(() => props.content, value => {
      state.value = JSON.parse(JSON.stringify(value));
      state.value.expires_in /= (60*60*1000)
    }, { immediate: true });

    const validate = () => {
        return true
    }
    const addCondition = () => {
      state.value.conditions.push({})
    }
    const emitSave = () => {
      if(!validate()) {
        notify({
          title: 'Validation Failed',
          text: 'Check the dates you submitted',
          type: 'error'
        })
      } else {
        emit('popup-save', state.value)
      } 
    }
    return {
      emitSave,
      state,
      promotionConstants,
      addCondition,
      props
    }
  }
}
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  width: 50%;
  height: 40%;
  border-radius: 10px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-content {
  width: 100%;
  height: 90%;
  overflow: scroll;

}
.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.popup-button {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px 15px;
}

.button-close {
  color: red;

}

.button-save {
  color: green;
}

.popup-title {
  font-size: 1.3rem;
  font-weight: bold;
}

.reward-table {
  width: 100%;
}
.row {
  width: 100%;
  display: grid;
}

.chest-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
</style>